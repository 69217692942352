@media (max-width: 1400px) {    
    .speaker-desc {
        height: 140px !important;
    }
}

@media (max-width: 1200px) {
    #header {
        height: 83px !important;
    }

    .amac-nav-logo {
        width: 55px !important;
    }

    .speaker-job {
        height: 70px !important;
    }
    
    .speaker-desc {
        height: 210px !important;
    }
}

@media (max-width: 991px) {
    #header {
        background: none !important;
        border: none !important;
        padding: 0 !important;
    }

    .amac-logo {
        width: 60% !important;
    }

    button.def, a.def {
        font-size: 1.2em !important;
    }

    .about-numbers {
        margin-top: 4em !important;
    }

    .sponsor-item {
        width: 250px !important;
    }

    .speaker-job {
        height: 40px !important;
    }
    
    .speaker-desc {
        height: 80px !important;
    }
}

@media (max-width: 767px) {
    .amac-logo {
        width: 70% !important;
    }

    .about-desc,
    .merchant-sub,
    .sponsor-sub {
        margin-left: auto;
        margin-right: auto;
    }

    .about-img,
    .merchant-img,
    .apply-sponsor-img {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .about-numbers {
        margin-top: 2em !important;
    }

    .ticket-inclusion-item {
        width: 40% !important;
    }

    .sponsors-row {
        flex-direction: column;
    }

    .sponsor-sub {
        width: 85% !important;
    }

    .accordion {
        width: 90% !important;
    }

    .speaker-job {
        height: 30px !important;
    }
    
    .speaker-desc {
        height: 80px !important;
    }

    .ticket-img-wrap {
        width: 60% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .schedule-list {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }

    .banner-sponsor-1-img-wrap,
    .banner-sponsor-2-img-wrap {
        width: 150px !important;
    }

    .tpt-logo {
        width: 120px !important;
    }

    .exhibit-sub {
        width: 80% !important;
    }
}

@media (max-width: 576px) {
    .amac-logo {
        width: 300px !important;
    }

    button.def, a.def {
        font-size: 1em !important;
    }

    .about-numbers {
        margin-top: 1em !important;
    }

    .sponsor-item,
    .sponsor-item-sm,
    .sponsor-item-md {
        width: 200px !important;
    }

    .past-amac-thumbnail,
    .events-thumbnail,
    .past-dec, 
    .speaker-item {
        width: 80% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .event-item-title {
        padding-left: 2.7em !important;
    }

    .speaker-job {
        height: auto !important;
    }
    
    .speaker-desc {
        height: auto !important;
    }

    .speakers-det-thumbnail {
        width: 300px !important;
        height: 300px !important;
    }

    .schedule-list {
        width: 70% !important;  
    }

    .banner-sponsor-1,
    .banner-sponsor-2 {
        width: 80% !important;
    }
}

@media (max-width: 440px) {
    .ticket-reg-btns {
        flex-direction: column !important;
    }

    .ticket-reg-btns button {
        width: 100% !important;
        margin: 0.3em 0 !important;
    }

    .schedule-list {
        width: 80% !important;  
    }
}

@media (max-width: 400px) {
    .amac-logo {
        width: 80% !important;
    }

    .ticket-inclusion-item {
        width: 50% !important;
    }

    .sponsor-item,
    .sponsor-item-sm,
    .sponsor-item-md {
        width: 170px !important;
    }

    .speakers-det-thumbnail {
        width: 250px !important;
        height: 250px !important;
    }
}

@media (max-width: 1400px) and (min-width: 992px) and (max-height: 900px) {
    .amac-logo {
        width: 35% !important;
    }
}