#art-schedule {
    padding: 5em 0;
    background: rgb(255,182,0);
    background: linear-gradient(39deg, rgba(255,182,0,1) 0%, rgba(255,113,88,1) 100%);
}

.art-sections {
    padding: 5em 0;
    height: 70vh;
}

.tpt-wrap {
    position: absolute;
    width: 300px;
    bottom: 0;
    right: 0;
}

.tpt-logo {
    width: 80px;
}

#transcendence {
    padding: 5em 0;
    background-color: white;
}

.trans-desc {
    width: 80%;
}