#header {
    height: 70px;
    z-index: 1000;
    padding: 8px 0;
    border-bottom: 1px solid #ffb600;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
}

.amac-nav-logo {
    width: 47px;
}

.amac-nav-logo-mobile {
    width: 45%;
    margin: 0 auto 30px;
}

#amac-nav li {
    list-style: none;
}

#amac-nav .main-nav:not(:first-child) {
    margin-left: 20px;
}

#amac-nav .main-nav a:not(.amac-nav-btn) {
    padding: 6px 10px;
    text-decoration: none;
}

#amac-nav li a:not(.amac-nav-btn):hover,
#amac-nav li a:not(.amac-nav-btn):focus {
    color: #c83e38 !important;
}

.amac-nav-btn {
    line-height: 1em;
}

.amac-nav-btn-mobile {
    font-size: 1em !important;
}

.amac-link {
    font-size: 1.2em !important;
}

.amac-link-mobile {
    font-size: 0.9em !important;
}