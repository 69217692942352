@import url(fonts.css);
@import url(https://pro.fontawesome.com/releases/v5.10.0/css/all.css);

* {
  box-sizing: border-box;
  font-family: Arvo;
  line-height: 1.2em;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Utility Classes */
/* Copyright: Tailwind CSS */
.h-0 {
  height: 0px;
}

.h-1 {
  height: 0.25rem;
}

.h-2 {
  height: 0.5rem;
}

.h-3 {
  height: 0.75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-1\.5 {
  height: 0.375rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.333333%;
}

.h-2\/3 {
  height: 66.666667%;
}

.h-1\/4 {
  height: 25%;
}

.h-2\/4 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-1\/5 {
  height: 20%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-1\/6 {
  height: 16.666667%;
}

.h-2\/6 {
  height: 33.333333%;
}

.h-3\/6 {
  height: 50%;
}

.h-4\/6 {
  height: 66.666667%;
}

.h-5\/6 {
  height: 83.333333%;
}

.h-full {
  height: 100%;
}

.h-screen {
  min-height: 100vh;
}

.max-h-0 {
  max-height: 0px;
}

.max-h-1 {
  max-height: 0.25rem;
}

.max-h-2 {
  max-height: 0.5rem;
}

.max-h-3 {
  max-height: 0.75rem;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.max-h-7 {
  max-height: 1.75rem;
}

.max-h-8 {
  max-height: 2rem;
}

.max-h-9 {
  max-height: 2.25rem;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-11 {
  max-height: 2.75rem;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-14 {
  max-height: 3.5rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-36 {
  max-height: 9rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-44 {
  max-height: 11rem;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-52 {
  max-height: 13rem;
}

.max-h-56 {
  max-height: 14rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-px {
  max-height: 1px;
}

.max-h-0\.5 {
  max-height: 0.125rem;
}

.max-h-1\.5 {
  max-height: 0.375rem;
}

.max-h-2\.5 {
  max-height: 0.625rem;
}

.max-h-3\.5 {
  max-height: 0.875rem;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-0 {
  min-height: 0px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.d0 {
  width: 0px;
}

.w-1 {
  width: 0.25rem;
}

.w-2 {
  width: 0.5rem;
}

.w-3 {
  width: 0.75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1\.5 {
  width: 0.375rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/6 {
  width: 33.333333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.666667%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-1\/12 {
  width: 8.333333%;
}

.w-2\/12 {
  width: 16.666667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.333333%;
}

.w-5\/12 {
  width: 41.666667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.333333%;
}

.w-8\/12 {
  width: 66.666667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-11\/12 {
  width: 91.666667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min {
  width: min-content;
}

.w-max {
  width: max-content;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-min {
  min-width: min-content;
}

.min-w-max {
  min-width: max-content;
}

.max-w-0 {
  max-width: 0rem;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-min {
  max-width: min-content;
}

.max-w-max {
  max-width: max-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

/* Font Weight */
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

/* Border Radius */
.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

/* Custom */
.font-size-40 {
  font-size: 0.4em;
}
.font-size-50 {
  font-size: 0.5em;
}
.font-size-60 {
  font-size: 0.6em;
}
.font-size-70 {
  font-size: 0.7em;
}
.font-size-80 {
  font-size: 0.8em;
}
.font-size-90 {
  font-size: 0.9em;
}
.font-size-100 {
  font-size: 1em;
}
.font-size-110 {
  font-size: 1.1em;
}
.font-size-120 {
  font-size: 1.2em;
}
.font-size-130 {
  font-size: 1.3em;
}
.font-size-140 {
  font-size: 1.4em;
}
.font-size-150 {
  font-size: 1.5em;
}
.font-size-160 {
  font-size: 1.6em;
}
.font-size-170 {
  font-size: 1.7em;
}
.font-size-180 {
  font-size: 1.8em;
}
.font-size-190 {
  font-size: 1.9em;
}
.font-size-200 {
  font-size: 2em;
}
.font-size-210 {
  font-size: 2.1em;
}
.font-size-220 {
  font-size: 2.2em;
}
.font-size-230 {
  font-size: 2.3em;
}
.font-size-240 {
  font-size: 2.4em;
}
.font-size-250 {
  font-size: 2.5em;
}
.font-size-260 {
  font-size: 2.6em;
}
.font-size-270 {
  font-size: 2.7em;
}
.font-size-280 {
  font-size: 2.8em;
}
.font-size-290 {
  font-size: 2.9em;
}
.font-size-300 {
  font-size: 3em;
}
.font-size-310 {
  font-size: 3.1em;
}
.font-size-320 {
  font-size: 3.2em;
}
.font-size-330 {
  font-size: 3.3em;
}
.font-size-340 {
  font-size: 3.4em;
}
.font-size-350 {
  font-size: 3.5em;
}
.font-size-360 {
  font-size: 3.6em;
}
.font-size-370 {
  font-size: 3.7em;
}
.font-size-380 {
  font-size: 3.8em;
}
.font-size-390 {
  font-size: 3.9em;
}
.font-size-400 {
  font-size: 4em;
}
.font-size-410 {
  font-size: 4.1em;
}
.font-size-420 {
  font-size: 4.2em;
}
.font-size-430 {
  font-size: 4.3em;
}
.font-size-440 {
  font-size: 4.4em;
}
.font-size-450 {
  font-size: 4.5em;
}
.font-size-460 {
  font-size: 4.6em;
}
.font-size-470 {
  font-size: 4.7em;
}
.font-size-480 {
  font-size: 4.8em;
}
.font-size-490 {
  font-size: 4.9em;
}
.font-size-500 {
  font-size: 5em;
}
.font-size-510 {
  font-size: 5.1em;
}
.font-size-520 {
  font-size: 5.2em;
}
.font-size-530 {
  font-size: 5.3em;
}
.font-size-540 {
  font-size: 5.4em;
}
.font-size-550 {
  font-size: 5.5em;
}
.font-size-560 {
  font-size: 5.6em;
}
.font-size-570 {
  font-size: 5.7em;
}
.font-size-580 {
  font-size: 5.8em;
}
.font-size-590 {
  font-size: 5.9em;
}
.font-size-600 {
  font-size: 6em;
}
.font-size-610 {
  font-size: 6.1em;
}
.font-size-620 {
  font-size: 6.2em;
}
.font-size-630 {
  font-size: 6.3em;
}
.font-size-640 {
  font-size: 6.4em;
}
.font-size-650 {
  font-size: 6.5em;
}
.font-size-660 {
  font-size: 6.6em;
}
.font-size-670 {
  font-size: 6.7em;
}
.font-size-680 {
  font-size: 6.8em;
}
.font-size-690 {
  font-size: 6.9em;
}
.font-size-700 {
  font-size: 7em;
}
.font-size-710 {
  font-size: 7.1em;
}
.font-size-720 {
  font-size: 7.2em;
}
.font-size-730 {
  font-size: 7.3em;
}
.font-size-740 {
  font-size: 7.4em;
}
.font-size-750 {
  font-size: 7.5em;
}
.font-size-760 {
  font-size: 7.6em;
}
.font-size-770 {
  font-size: 7.7em;
}
.font-size-780 {
  font-size: 7.8em;
}
.font-size-790 {
  font-size: 7.9em;
}
.font-size-800 {
  font-size: 8em;
}
.font-size-810 {
  font-size: 8.1em;
}
.font-size-820 {
  font-size: 8.2em;
}
.font-size-830 {
  font-size: 8.3em;
}
.font-size-840 {
  font-size: 8.4em;
}
.font-size-850 {
  font-size: 8.5em;
}
.font-size-860 {
  font-size: 8.6em;
}
.font-size-870 {
  font-size: 8.7em;
}
.font-size-880 {
  font-size: 8.8em;
}
.font-size-890 {
  font-size: 8.9em;
}
.font-size-900 {
  font-size: 9em;
}
@media (min-width: 576px) {
  .font-size-sm-40 {
    font-size: 0.4em;
  }
  .font-size-sm-50 {
    font-size: 0.5em;
  }
  .font-size-sm-60 {
    font-size: 0.6em;
  }
  .font-size-sm-70 {
    font-size: 0.7em;
  }
  .font-size-sm-70 {
    font-size: 0.7em;
  }
  .font-size-sm-80 {
    font-size: 0.8em;
  }
  .font-size-sm-90 {
    font-size: 0.9em;
  }
  .font-size-sm-100 {
    font-size: 1em;
  }
  .font-size-sm-110 {
    font-size: 1.1em;
  }
  .font-size-sm-120 {
    font-size: 1.2em;
  }
  .font-size-sm-130 {
    font-size: 1.3em;
  }
  .font-size-sm-140 {
    font-size: 1.4em;
  }
  .font-size-sm-150 {
    font-size: 1.5em;
  }
  .font-size-sm-160 {
    font-size: 1.6em;
  }
  .font-size-sm-170 {
    font-size: 1.7em;
  }
  .font-size-sm-180 {
    font-size: 1.8em;
  }
  .font-size-sm-190 {
    font-size: 1.9em;
  }
  .font-size-sm-200 {
    font-size: 2em;
  }
  .font-size-sm-210 {
    font-size: 2.1em;
  }
  .font-size-sm-220 {
    font-size: 2.2em;
  }
  .font-size-sm-230 {
    font-size: 2.3em;
  }
  .font-size-sm-240 {
    font-size: 2.4em;
  }
  .font-size-sm-250 {
    font-size: 2.5em;
  }
  .font-size-sm-260 {
    font-size: 2.6em;
  }
  .font-size-sm-270 {
    font-size: 2.7em;
  }
  .font-size-sm-280 {
    font-size: 2.8em;
  }
  .font-size-sm-290 {
    font-size: 2.9em;
  }
  .font-size-sm-300 {
    font-size: 3em;
  }
  .font-size-sm-310 {
    font-size: 3.1em;
  }
  .font-size-sm-320 {
    font-size: 3.2em;
  }
  .font-size-sm-330 {
    font-size: 3.3em;
  }
  .font-size-sm-340 {
    font-size: 3.4em;
  }
  .font-size-sm-350 {
    font-size: 3.5em;
  }
  .font-size-sm-360 {
    font-size: 3.6em;
  }
  .font-size-sm-370 {
    font-size: 3.7em;
  }
  .font-size-sm-380 {
    font-size: 3.8em;
  }
  .font-size-sm-390 {
    font-size: 3.9em;
  }
  .font-size-sm-400 {
    font-size: 4em;
  }
  .font-size-sm-410 {
    font-size: 4.1em;
  }
  .font-size-sm-420 {
    font-size: 4.2em;
  }
  .font-size-sm-430 {
    font-size: 4.3em;
  }
  .font-size-sm-440 {
    font-size: 4.4em;
  }
  .font-size-sm-450 {
    font-size: 4.5em;
  }
  .font-size-sm-460 {
    font-size: 4.6em;
  }
  .font-size-sm-470 {
    font-size: 4.7em;
  }
  .font-size-sm-480 {
    font-size: 4.8em;
  }
  .font-size-sm-490 {
    font-size: 4.9em;
  }
  .font-size-sm-500 {
    font-size: 5em;
  }
  .font-size-sm-510 {
    font-size: 5.1em;
  }
  .font-size-sm-520 {
    font-size: 5.2em;
  }
  .font-size-sm-530 {
    font-size: 5.3em;
  }
  .font-size-sm-540 {
    font-size: 5.4em;
  }
  .font-size-sm-550 {
    font-size: 5.5em;
  }
  .font-size-sm-560 {
    font-size: 5.6em;
  }
  .font-size-sm-570 {
    font-size: 5.7em;
  }
  .font-size-sm-580 {
    font-size: 5.8em;
  }
  .font-size-sm-590 {
    font-size: 5.9em;
  }
  .font-size-sm-600 {
    font-size: 6em;
  }
  .font-size-sm-610 {
    font-size: 6.1em;
  }
  .font-size-sm-620 {
    font-size: 6.2em;
  }
  .font-size-sm-630 {
    font-size: 6.3em;
  }
  .font-size-sm-640 {
    font-size: 6.4em;
  }
  .font-size-sm-650 {
    font-size: 6.5em;
  }
  .font-size-sm-660 {
    font-size: 6.6em;
  }
  .font-size-sm-670 {
    font-size: 6.7em;
  }
  .font-size-sm-680 {
    font-size: 6.8em;
  }
  .font-size-sm-690 {
    font-size: 6.9em;
  }
  .font-size-sm-700 {
    font-size: 7em;
  }
  .font-size-sm-710 {
    font-size: 7.1em;
  }
  .font-size-sm-720 {
    font-size: 7.2em;
  }
  .font-size-sm-730 {
    font-size: 7.3em;
  }
  .font-size-sm-740 {
    font-size: 7.4em;
  }
  .font-size-sm-750 {
    font-size: 7.5em;
  }
  .font-size-sm-760 {
    font-size: 7.6em;
  }
  .font-size-sm-770 {
    font-size: 7.7em;
  }
  .font-size-sm-780 {
    font-size: 7.8em;
  }
  .font-size-sm-790 {
    font-size: 7.9em;
  }
  .font-size-sm-800 {
    font-size: 8em;
  }
  .font-size-sm-810 {
    font-size: 8.1em;
  }
  .font-size-sm-820 {
    font-size: 8.2em;
  }
  .font-size-sm-830 {
    font-size: 8.3em;
  }
  .font-size-sm-840 {
    font-size: 8.4em;
  }
  .font-size-sm-850 {
    font-size: 8.5em;
  }
  .font-size-sm-860 {
    font-size: 8.6em;
  }
  .font-size-sm-870 {
    font-size: 8.7em;
  }
  .font-size-sm-880 {
    font-size: 8.8em;
  }
  .font-size-sm-890 {
    font-size: 8.9em;
  }
  .font-size-sm-900 {
    font-size: 9em;
  }
}
@media (min-width: 768px) {
  .font-size-md-40 {
    font-size: 0.4em;
  }
  .font-size-md-50 {
    font-size: 0.5em;
  }
  .font-size-md-60 {
    font-size: 0.6em;
  }
  .font-size-md-70 {
    font-size: 0.7em;
  }
  .font-size-md-70 {
    font-size: 0.7em;
  }
  .font-size-md-80 {
    font-size: 0.8em;
  }
  .font-size-md-90 {
    font-size: 0.9em;
  }
  .font-size-md-100 {
    font-size: 1em;
  }
  .font-size-md-110 {
    font-size: 1.1em;
  }
  .font-size-md-120 {
    font-size: 1.2em;
  }
  .font-size-md-130 {
    font-size: 1.3em;
  }
  .font-size-md-140 {
    font-size: 1.4em;
  }
  .font-size-md-150 {
    font-size: 1.5em;
  }
  .font-size-md-160 {
    font-size: 1.6em;
  }
  .font-size-md-170 {
    font-size: 1.7em;
  }
  .font-size-md-180 {
    font-size: 1.8em;
  }
  .font-size-md-190 {
    font-size: 1.9em;
  }
  .font-size-md-200 {
    font-size: 2em;
  }
  .font-size-md-210 {
    font-size: 2.1em;
  }
  .font-size-md-220 {
    font-size: 2.2em;
  }
  .font-size-md-230 {
    font-size: 2.3em;
  }
  .font-size-md-240 {
    font-size: 2.4em;
  }
  .font-size-md-250 {
    font-size: 2.5em;
  }
  .font-size-md-260 {
    font-size: 2.6em;
  }
  .font-size-md-270 {
    font-size: 2.7em;
  }
  .font-size-md-280 {
    font-size: 2.8em;
  }
  .font-size-md-290 {
    font-size: 2.9em;
  }
  .font-size-md-300 {
    font-size: 3em;
  }
  .font-size-md-310 {
    font-size: 3.1em;
  }
  .font-size-md-320 {
    font-size: 3.2em;
  }
  .font-size-md-330 {
    font-size: 3.3em;
  }
  .font-size-md-340 {
    font-size: 3.4em;
  }
  .font-size-md-350 {
    font-size: 3.5em;
  }
  .font-size-md-360 {
    font-size: 3.6em;
  }
  .font-size-md-370 {
    font-size: 3.7em;
  }
  .font-size-md-380 {
    font-size: 3.8em;
  }
  .font-size-md-390 {
    font-size: 3.9em;
  }
  .font-size-md-400 {
    font-size: 4em;
  }
  .font-size-md-410 {
    font-size: 4.1em;
  }
  .font-size-md-420 {
    font-size: 4.2em;
  }
  .font-size-md-430 {
    font-size: 4.3em;
  }
  .font-size-md-440 {
    font-size: 4.4em;
  }
  .font-size-md-450 {
    font-size: 4.5em;
  }
  .font-size-md-460 {
    font-size: 4.6em;
  }
  .font-size-md-470 {
    font-size: 4.7em;
  }
  .font-size-md-480 {
    font-size: 4.8em;
  }
  .font-size-md-490 {
    font-size: 4.9em;
  }
  .font-size-md-500 {
    font-size: 5em;
  }
  .font-size-md-510 {
    font-size: 5.1em;
  }
  .font-size-md-520 {
    font-size: 5.2em;
  }
  .font-size-md-530 {
    font-size: 5.3em;
  }
  .font-size-md-540 {
    font-size: 5.4em;
  }
  .font-size-md-550 {
    font-size: 5.5em;
  }
  .font-size-md-560 {
    font-size: 5.6em;
  }
  .font-size-md-570 {
    font-size: 5.7em;
  }
  .font-size-md-580 {
    font-size: 5.8em;
  }
  .font-size-md-590 {
    font-size: 5.9em;
  }
  .font-size-md-600 {
    font-size: 6em;
  }
  .font-size-md-610 {
    font-size: 6.1em;
  }
  .font-size-md-620 {
    font-size: 6.2em;
  }
  .font-size-md-630 {
    font-size: 6.3em;
  }
  .font-size-md-640 {
    font-size: 6.4em;
  }
  .font-size-md-650 {
    font-size: 6.5em;
  }
  .font-size-md-660 {
    font-size: 6.6em;
  }
  .font-size-md-670 {
    font-size: 6.7em;
  }
  .font-size-md-680 {
    font-size: 6.8em;
  }
  .font-size-md-690 {
    font-size: 6.9em;
  }
  .font-size-md-700 {
    font-size: 7em;
  }
  .font-size-md-710 {
    font-size: 7.1em;
  }
  .font-size-md-720 {
    font-size: 7.2em;
  }
  .font-size-md-730 {
    font-size: 7.3em;
  }
  .font-size-md-740 {
    font-size: 7.4em;
  }
  .font-size-md-750 {
    font-size: 7.5em;
  }
  .font-size-md-760 {
    font-size: 7.6em;
  }
  .font-size-md-770 {
    font-size: 7.7em;
  }
  .font-size-md-780 {
    font-size: 7.8em;
  }
  .font-size-md-790 {
    font-size: 7.9em;
  }
  .font-size-md-800 {
    font-size: 8em;
  }
  .font-size-md-810 {
    font-size: 8.1em;
  }
  .font-size-md-820 {
    font-size: 8.2em;
  }
  .font-size-md-830 {
    font-size: 8.3em;
  }
  .font-size-md-840 {
    font-size: 8.4em;
  }
  .font-size-md-850 {
    font-size: 8.5em;
  }
  .font-size-md-860 {
    font-size: 8.6em;
  }
  .font-size-md-870 {
    font-size: 8.7em;
  }
  .font-size-md-880 {
    font-size: 8.8em;
  }
  .font-size-md-890 {
    font-size: 8.9em;
  }
  .font-size-md-900 {
    font-size: 9em;
  }
}
@media (min-width: 992px) {
  .font-size-lg-40 {
    font-size: 0.4em;
  }
  .font-size-lg-50 {
    font-size: 0.5em;
  }
  .font-size-lg-60 {
    font-size: 0.6em;
  }
  .font-size-lg-70 {
    font-size: 0.7em;
  }
  .font-size-lg-70 {
    font-size: 0.7em;
  }
  .font-size-lg-80 {
    font-size: 0.8em;
  }
  .font-size-lg-90 {
    font-size: 0.9em;
  }
  .font-size-lg-100 {
    font-size: 1em;
  }
  .font-size-lg-110 {
    font-size: 1.1em;
  }
  .font-size-lg-120 {
    font-size: 1.2em;
  }
  .font-size-lg-130 {
    font-size: 1.3em;
  }
  .font-size-lg-140 {
    font-size: 1.4em;
  }
  .font-size-lg-150 {
    font-size: 1.5em;
  }
  .font-size-lg-160 {
    font-size: 1.6em;
  }
  .font-size-lg-170 {
    font-size: 1.7em;
  }
  .font-size-lg-180 {
    font-size: 1.8em;
  }
  .font-size-lg-190 {
    font-size: 1.9em;
  }
  .font-size-lg-200 {
    font-size: 2em;
  }
  .font-size-lg-210 {
    font-size: 2.1em;
  }
  .font-size-lg-220 {
    font-size: 2.2em;
  }
  .font-size-lg-230 {
    font-size: 2.3em;
  }
  .font-size-lg-240 {
    font-size: 2.4em;
  }
  .font-size-lg-250 {
    font-size: 2.5em;
  }
  .font-size-lg-260 {
    font-size: 2.6em;
  }
  .font-size-lg-270 {
    font-size: 2.7em;
  }
  .font-size-lg-280 {
    font-size: 2.8em;
  }
  .font-size-lg-290 {
    font-size: 2.9em;
  }
  .font-size-lg-300 {
    font-size: 3em;
  }
  .font-size-lg-310 {
    font-size: 3.1em;
  }
  .font-size-lg-320 {
    font-size: 3.2em;
  }
  .font-size-lg-330 {
    font-size: 3.3em;
  }
  .font-size-lg-340 {
    font-size: 3.4em;
  }
  .font-size-lg-350 {
    font-size: 3.5em;
  }
  .font-size-lg-360 {
    font-size: 3.6em;
  }
  .font-size-lg-370 {
    font-size: 3.7em;
  }
  .font-size-lg-380 {
    font-size: 3.8em;
  }
  .font-size-lg-390 {
    font-size: 3.9em;
  }
  .font-size-lg-400 {
    font-size: 4em;
  }
  .font-size-lg-410 {
    font-size: 4.1em;
  }
  .font-size-lg-420 {
    font-size: 4.2em;
  }
  .font-size-lg-430 {
    font-size: 4.3em;
  }
  .font-size-lg-440 {
    font-size: 4.4em;
  }
  .font-size-lg-450 {
    font-size: 4.5em;
  }
  .font-size-lg-460 {
    font-size: 4.6em;
  }
  .font-size-lg-470 {
    font-size: 4.7em;
  }
  .font-size-lg-480 {
    font-size: 4.8em;
  }
  .font-size-lg-490 {
    font-size: 4.9em;
  }
  .font-size-lg-500 {
    font-size: 5em;
  }
  .font-size-lg-510 {
    font-size: 5.1em;
  }
  .font-size-lg-520 {
    font-size: 5.2em;
  }
  .font-size-lg-530 {
    font-size: 5.3em;
  }
  .font-size-lg-540 {
    font-size: 5.4em;
  }
  .font-size-lg-550 {
    font-size: 5.5em;
  }
  .font-size-lg-560 {
    font-size: 5.6em;
  }
  .font-size-lg-570 {
    font-size: 5.7em;
  }
  .font-size-lg-580 {
    font-size: 5.8em;
  }
  .font-size-lg-590 {
    font-size: 5.9em;
  }
  .font-size-lg-600 {
    font-size: 6em;
  }
  .font-size-lg-610 {
    font-size: 6.1em;
  }
  .font-size-lg-620 {
    font-size: 6.2em;
  }
  .font-size-lg-630 {
    font-size: 6.3em;
  }
  .font-size-lg-640 {
    font-size: 6.4em;
  }
  .font-size-lg-650 {
    font-size: 6.5em;
  }
  .font-size-lg-660 {
    font-size: 6.6em;
  }
  .font-size-lg-670 {
    font-size: 6.7em;
  }
  .font-size-lg-680 {
    font-size: 6.8em;
  }
  .font-size-lg-690 {
    font-size: 6.9em;
  }
  .font-size-lg-700 {
    font-size: 7em;
  }
  .font-size-lg-710 {
    font-size: 7.1em;
  }
  .font-size-lg-720 {
    font-size: 7.2em;
  }
  .font-size-lg-730 {
    font-size: 7.3em;
  }
  .font-size-lg-740 {
    font-size: 7.4em;
  }
  .font-size-lg-750 {
    font-size: 7.5em;
  }
  .font-size-lg-760 {
    font-size: 7.6em;
  }
  .font-size-lg-770 {
    font-size: 7.7em;
  }
  .font-size-lg-780 {
    font-size: 7.8em;
  }
  .font-size-lg-790 {
    font-size: 7.9em;
  }
  .font-size-lg-800 {
    font-size: 8em;
  }
  .font-size-lg-810 {
    font-size: 8.1em;
  }
  .font-size-lg-820 {
    font-size: 8.2em;
  }
  .font-size-lg-830 {
    font-size: 8.3em;
  }
  .font-size-lg-840 {
    font-size: 8.4em;
  }
  .font-size-lg-850 {
    font-size: 8.5em;
  }
  .font-size-lg-860 {
    font-size: 8.6em;
  }
  .font-size-lg-870 {
    font-size: 8.7em;
  }
  .font-size-lg-880 {
    font-size: 8.8em;
  }
  .font-size-lg-890 {
    font-size: 8.9em;
  }
  .font-size-lg-900 {
    font-size: 9em;
  }
}
@media (min-width: 1200px) {
  .font-size-xl-40 {
    font-size: 0.4em;
  }
  .font-size-xl-50 {
    font-size: 0.5em;
  }
  .font-size-xl-60 {
    font-size: 0.6em;
  }
  .font-size-xl-70 {
    font-size: 0.7em;
  }
  .font-size-xl-70 {
    font-size: 0.7em;
  }
  .font-size-xl-80 {
    font-size: 0.8em;
  }
  .font-size-xl-90 {
    font-size: 0.9em;
  }
  .font-size-xl-100 {
    font-size: 1em;
  }
  .font-size-xl-110 {
    font-size: 1.1em;
  }
  .font-size-xl-120 {
    font-size: 1.2em;
  }
  .font-size-xl-130 {
    font-size: 1.3em;
  }
  .font-size-xl-140 {
    font-size: 1.4em;
  }
  .font-size-xl-150 {
    font-size: 1.5em;
  }
  .font-size-xl-160 {
    font-size: 1.6em;
  }
  .font-size-xl-170 {
    font-size: 1.7em;
  }
  .font-size-xl-180 {
    font-size: 1.8em;
  }
  .font-size-xl-190 {
    font-size: 1.9em;
  }
  .font-size-xl-200 {
    font-size: 2em;
  }
  .font-size-xl-210 {
    font-size: 2.1em;
  }
  .font-size-xl-220 {
    font-size: 2.2em;
  }
  .font-size-xl-230 {
    font-size: 2.3em;
  }
  .font-size-xl-240 {
    font-size: 2.4em;
  }
  .font-size-xl-250 {
    font-size: 2.5em;
  }
  .font-size-xl-260 {
    font-size: 2.6em;
  }
  .font-size-xl-270 {
    font-size: 2.7em;
  }
  .font-size-xl-280 {
    font-size: 2.8em;
  }
  .font-size-xl-290 {
    font-size: 2.9em;
  }
  .font-size-xl-300 {
    font-size: 3em;
  }
  .font-size-xl-310 {
    font-size: 3.1em;
  }
  .font-size-xl-320 {
    font-size: 3.2em;
  }
  .font-size-xl-330 {
    font-size: 3.3em;
  }
  .font-size-xl-340 {
    font-size: 3.4em;
  }
  .font-size-xl-350 {
    font-size: 3.5em;
  }
  .font-size-xl-360 {
    font-size: 3.6em;
  }
  .font-size-xl-370 {
    font-size: 3.7em;
  }
  .font-size-xl-380 {
    font-size: 3.8em;
  }
  .font-size-xl-390 {
    font-size: 3.9em;
  }
  .font-size-xl-400 {
    font-size: 4em;
  }
  .font-size-xl-410 {
    font-size: 4.1em;
  }
  .font-size-xl-420 {
    font-size: 4.2em;
  }
  .font-size-xl-430 {
    font-size: 4.3em;
  }
  .font-size-xl-440 {
    font-size: 4.4em;
  }
  .font-size-xl-450 {
    font-size: 4.5em;
  }
  .font-size-xl-460 {
    font-size: 4.6em;
  }
  .font-size-xl-470 {
    font-size: 4.7em;
  }
  .font-size-xl-480 {
    font-size: 4.8em;
  }
  .font-size-xl-490 {
    font-size: 4.9em;
  }
  .font-size-xl-500 {
    font-size: 5em;
  }
  .font-size-xl-510 {
    font-size: 5.1em;
  }
  .font-size-xl-520 {
    font-size: 5.2em;
  }
  .font-size-xl-530 {
    font-size: 5.3em;
  }
  .font-size-xl-540 {
    font-size: 5.4em;
  }
  .font-size-xl-550 {
    font-size: 5.5em;
  }
  .font-size-xl-560 {
    font-size: 5.6em;
  }
  .font-size-xl-570 {
    font-size: 5.7em;
  }
  .font-size-xl-580 {
    font-size: 5.8em;
  }
  .font-size-xl-590 {
    font-size: 5.9em;
  }
  .font-size-xl-600 {
    font-size: 6em;
  }
  .font-size-xl-610 {
    font-size: 6.1em;
  }
  .font-size-xl-620 {
    font-size: 6.2em;
  }
  .font-size-xl-630 {
    font-size: 6.3em;
  }
  .font-size-xl-640 {
    font-size: 6.4em;
  }
  .font-size-xl-650 {
    font-size: 6.5em;
  }
  .font-size-xl-660 {
    font-size: 6.6em;
  }
  .font-size-xl-670 {
    font-size: 6.7em;
  }
  .font-size-xl-680 {
    font-size: 6.8em;
  }
  .font-size-xl-690 {
    font-size: 6.9em;
  }
  .font-size-xl-700 {
    font-size: 7em;
  }
  .font-size-xl-710 {
    font-size: 7.1em;
  }
  .font-size-xl-720 {
    font-size: 7.2em;
  }
  .font-size-xl-730 {
    font-size: 7.3em;
  }
  .font-size-xl-740 {
    font-size: 7.4em;
  }
  .font-size-xl-750 {
    font-size: 7.5em;
  }
  .font-size-xl-760 {
    font-size: 7.6em;
  }
  .font-size-xl-770 {
    font-size: 7.7em;
  }
  .font-size-xl-780 {
    font-size: 7.8em;
  }
  .font-size-xl-790 {
    font-size: 7.9em;
  }
  .font-size-xl-800 {
    font-size: 8em;
  }
  .font-size-xl-810 {
    font-size: 8.1em;
  }
  .font-size-xl-820 {
    font-size: 8.2em;
  }
  .font-size-xl-830 {
    font-size: 8.3em;
  }
  .font-size-xl-840 {
    font-size: 8.4em;
  }
  .font-size-xl-850 {
    font-size: 8.5em;
  }
  .font-size-xl-860 {
    font-size: 8.6em;
  }
  .font-size-xl-870 {
    font-size: 8.7em;
  }
  .font-size-xl-880 {
    font-size: 8.8em;
  }
  .font-size-xl-890 {
    font-size: 8.9em;
  }
  .font-size-xl-900 {
    font-size: 9em;
  }
}
@media (min-width: 1400px) {
  .font-size-xxl-40 {
    font-size: 0.4em;
  }
  .font-size-xxl-50 {
    font-size: 0.5em;
  }
  .font-size-xxl-60 {
    font-size: 0.6em;
  }
  .font-size-xxl-70 {
    font-size: 0.7em;
  }
  .font-size-xxl-70 {
    font-size: 0.7em;
  }
  .font-size-xxl-80 {
    font-size: 0.8em;
  }
  .font-size-xxl-90 {
    font-size: 0.9em;
  }
  .font-size-xxl-100 {
    font-size: 1em;
  }
  .font-size-xxl-110 {
    font-size: 1.1em;
  }
  .font-size-xxl-120 {
    font-size: 1.2em;
  }
  .font-size-xxl-130 {
    font-size: 1.3em;
  }
  .font-size-xxl-140 {
    font-size: 1.4em;
  }
  .font-size-xxl-150 {
    font-size: 1.5em;
  }
  .font-size-xxl-160 {
    font-size: 1.6em;
  }
  .font-size-xxl-170 {
    font-size: 1.7em;
  }
  .font-size-xxl-180 {
    font-size: 1.8em;
  }
  .font-size-xxl-190 {
    font-size: 1.9em;
  }
  .font-size-xxl-200 {
    font-size: 2em;
  }
  .font-size-xxl-210 {
    font-size: 2.1em;
  }
  .font-size-xxl-220 {
    font-size: 2.2em;
  }
  .font-size-xxl-230 {
    font-size: 2.3em;
  }
  .font-size-xxl-240 {
    font-size: 2.4em;
  }
  .font-size-xxl-250 {
    font-size: 2.5em;
  }
  .font-size-xxl-260 {
    font-size: 2.6em;
  }
  .font-size-xxl-270 {
    font-size: 2.7em;
  }
  .font-size-xxl-280 {
    font-size: 2.8em;
  }
  .font-size-xxl-290 {
    font-size: 2.9em;
  }
  .font-size-xxl-300 {
    font-size: 3em;
  }
  .font-size-xxl-310 {
    font-size: 3.1em;
  }
  .font-size-xxl-320 {
    font-size: 3.2em;
  }
  .font-size-xxl-330 {
    font-size: 3.3em;
  }
  .font-size-xxl-340 {
    font-size: 3.4em;
  }
  .font-size-xxl-350 {
    font-size: 3.5em;
  }
  .font-size-xxl-360 {
    font-size: 3.6em;
  }
  .font-size-xxl-370 {
    font-size: 3.7em;
  }
  .font-size-xxl-380 {
    font-size: 3.8em;
  }
  .font-size-xxl-390 {
    font-size: 3.9em;
  }
  .font-size-xxl-400 {
    font-size: 4em;
  }
  .font-size-xxl-410 {
    font-size: 4.1em;
  }
  .font-size-xxl-420 {
    font-size: 4.2em;
  }
  .font-size-xxl-430 {
    font-size: 4.3em;
  }
  .font-size-xxl-440 {
    font-size: 4.4em;
  }
  .font-size-xxl-450 {
    font-size: 4.5em;
  }
  .font-size-xxl-460 {
    font-size: 4.6em;
  }
  .font-size-xxl-470 {
    font-size: 4.7em;
  }
  .font-size-xxl-480 {
    font-size: 4.8em;
  }
  .font-size-xxl-490 {
    font-size: 4.9em;
  }
  .font-size-xxl-500 {
    font-size: 5em;
  }
  .font-size-xxl-510 {
    font-size: 5.1em;
  }
  .font-size-xxl-520 {
    font-size: 5.2em;
  }
  .font-size-xxl-530 {
    font-size: 5.3em;
  }
  .font-size-xxl-540 {
    font-size: 5.4em;
  }
  .font-size-xxl-550 {
    font-size: 5.5em;
  }
  .font-size-xxl-560 {
    font-size: 5.6em;
  }
  .font-size-xxl-570 {
    font-size: 5.7em;
  }
  .font-size-xxl-580 {
    font-size: 5.8em;
  }
  .font-size-xxl-590 {
    font-size: 5.9em;
  }
  .font-size-xxl-600 {
    font-size: 6em;
  }
  .font-size-xxl-610 {
    font-size: 6.1em;
  }
  .font-size-xxl-620 {
    font-size: 6.2em;
  }
  .font-size-xxl-630 {
    font-size: 6.3em;
  }
  .font-size-xxl-640 {
    font-size: 6.4em;
  }
  .font-size-xxl-650 {
    font-size: 6.5em;
  }
  .font-size-xxl-660 {
    font-size: 6.6em;
  }
  .font-size-xxl-670 {
    font-size: 6.7em;
  }
  .font-size-xxl-680 {
    font-size: 6.8em;
  }
  .font-size-xxl-690 {
    font-size: 6.9em;
  }
  .font-size-xxl-700 {
    font-size: 7em;
  }
  .font-size-xxl-710 {
    font-size: 7.1em;
  }
  .font-size-xxl-720 {
    font-size: 7.2em;
  }
  .font-size-xxl-730 {
    font-size: 7.3em;
  }
  .font-size-xxl-740 {
    font-size: 7.4em;
  }
  .font-size-xxl-750 {
    font-size: 7.5em;
  }
  .font-size-xxl-760 {
    font-size: 7.6em;
  }
  .font-size-xxl-770 {
    font-size: 7.7em;
  }
  .font-size-xxl-780 {
    font-size: 7.8em;
  }
  .font-size-xxl-790 {
    font-size: 7.9em;
  }
  .font-size-xxl-800 {
    font-size: 8em;
  }
  .font-size-xxl-810 {
    font-size: 8.1em;
  }
  .font-size-xxl-820 {
    font-size: 8.2em;
  }
  .font-size-xxl-830 {
    font-size: 8.3em;
  }
  .font-size-xxl-840 {
    font-size: 8.4em;
  }
  .font-size-xxl-850 {
    font-size: 8.5em;
  }
  .font-size-xxl-860 {
    font-size: 8.6em;
  }
  .font-size-xxl-870 {
    font-size: 8.7em;
  }
  .font-size-xxl-880 {
    font-size: 8.8em;
  }
  .font-size-xxl-890 {
    font-size: 8.9em;
  }
  .font-size-xxl-900 {
    font-size: 9em;
  }
}
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 100;
}
.line-height-80 {
  line-height: 0.8em !important;
}
.line-height-90 {
  line-height: 0.9em !important;
}
.line-height-100 {
  line-height: 1em !important;
}
.line-height-110 {
  line-height: 1.1em !important;
}
.line-height-120 {
  line-height: 1.2em !important;
}
.line-height-130 {
  line-height: 1.3em !important;
}
.line-height-140 {
  line-height: 1.4em !important;
}
.line-height-150 {
  line-height: 1.5em !important;
}
.text-color-1 {
  color: #BBEBD6 !important;
}
.text-color-2 {
  color: #ffb600 !important;
}
.text-color-3 {
  color: #ff7158 !important;
}
.text-color-4 {
  color: #c83e38 !important;
}
.text-color-5 {
  color: #888888 !important;
}
.text-color-6 {
  color: #1c1c24 !important;
}
.link-color-1 {
  color: #888888 !important;
}
.link-color-1:hover {
  color: #ff7158 !important;
  text-decoration: underline;
}
.link-color-1 {
  color: #ffffff !important;
} 
.link-color-1:hover {
  color: #ff7158 !important;
  text-decoration: underline;
}
.link-color-2 {
  color: #888888 !important;
} 
.link-color-2:hover {
  color: #ff7158 !important;
  text-decoration: underline;
}
.link-color-3 {
  color: #1c1c24 !important;
} 
.link-color-3:hover {
  color: #c83e38 !important;
  text-decoration: underline;
}
.bg-color-1 {
  background-color: #ffb600 !important;
}
.bg-color-2 {
  background-color: #2c2c2c !important;
}
.bg-color-3 {
  background-color: #BBEBD6 !important;
}
button.def, 
a.def {
  font-family: Arvo !important;
  font-size: 1.4em !important;
  font-weight: bold;
  letter-spacing: 0.15rem;   
}
.btn-custom-1 {
  background-color: #d04330 !important;
  color: #ffffff !important;
}
.btn-custom-1:hover,
.btn-custom-1:active,
.btn-custom-1:focus {
  background-color: #c03220 !important;
  color: #ffffff !important;
}
.btn-custom-2 {
  background-color: #ffab53 !important;
  color: #ffffff !important;
}
.btn-custom-2:hover,
.btn-custom-2:active,
.btn-custom-2:focus {
  background-color: #ff9a42 !important;
  color: #ffffff !important;
}
.btn-custom-3 {
  background-color: #ff7158 !important;
  color: #ffffff !important;
}
.btn-custom-3:hover,
.btn-custom-3:active,
.btn-custom-3:focus {
  background-color: #ff6047 !important;
  color: #ffffff !important;
}
.btn-custom-4 {
  background-color: #c83e38 !important;
  color: #ffffff !important;
}
.btn-custom-4:hover,
.btn-custom-4:active,
.btn-custom-4:focus {
  background-color: #b72d27 !important;
  color: #ffffff !important;
}
.cursor-pointer {
  cursor: pointer;
}
.letter-spacing-1 {
  letter-spacing: 0.01em;
}
.letter-spacing-5 {
  letter-spacing: 0.05em;
}
.shadow-custom-1 {
  box-shadow: 5px 5px 8px -4px rgba(0, 0, 0, 0.85);
}
.cursor-pointer {
  cursor: pointer;
}
.background-image-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-image-contain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

iframe {
  width: 100% !important;
}

.resize-none {
  resize: none; 
}