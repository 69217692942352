#partners {
    padding: 5em 0;
    background-color: #F9FBFD;
}

.sponsor-item {
    width: 300px;
}

.sponsor-item.gold {
    width: 350px;
}

.sponsor-item-sm {
    width: 150px;
}

.sponsor-item-md {
    width: 200px;
}

.sponsors-section {
    margin: 2.5em 0;
}

