#schedule {
    padding: 5em 0;
}

.schedule-title-a {
    width: 38%;
    margin-right: 2%;
}

.schedule-title-b {
    width: 58%;
    margin-left: 2%;
}