#amac-banner {
  padding: 70px 0;
}

.banner-sub {
  margin-bottom: 2.3rem;
}

.banner-sponsor-1-img-wrap,
.banner-sponsor-2-img-wrap {
  width: 200px;
}