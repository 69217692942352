#apply-sponsor {
    padding: 5em 0;
    background-attachment: fixed;
}

.apply-sponsor-img {
    border-radius: 50px 0 50px 0;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
}

.apply-sponsor-img img {
    border-radius: 50px 0 50px 0;
}