@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo-Bold.woff2') format('woff2'),
      url('./fonts/Arvo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.arvo-bold {
  font-family: Arvo;
  font-weight: bold;
}
@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo.woff2') format('woff2'),
      url('./fonts/Arvo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.arvo {
  font-family: Arvo;
}
@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo-Italic.woff2') format('woff2'),
      url('./fonts/Arvo-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
.arvo-italic {
  font-family: Arvo;
  font-style: italic;
}
@font-face {
  font-family: 'Arvo';
  src: url('./fonts/Arvo-BoldItalic.woff2') format('woff2'),
      url('./fonts/Arvo-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
.arvo-bold-italic {
  font-family: Arvo;
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: NeogroteskEss-Regular;
  src: url("./fonts/Neo/NeogroteskEss-Regular.otf");
}
.neo-regular {
  font-family: NeogroteskEss-Regular;
}
@font-face {
  font-family: NeogroteskEss-Bold;
  src: url("./fonts/Neo/NeogroteskEss-Bold.otf");
}
.neo-bold {
  font-family: NeogroteskEss-Bold;
}
@font-face {
  font-family: NeogroteskEss-Black;
  src: url("./fonts/Neo/NeogroteskEss-Black.otf");
}
.neo-black {
  font-family: NeogroteskEss-Black;
}
@font-face {
  font-family: NeogroteskEss-RegularIt;
  src: url("./fonts/Neo/NeogroteskEss-RegularIt.otf");
}
.neo-regular-italic {
  font-family: NeogroteskEss-RegularIt;
}
@font-face {
  font-family: NeogroteskEss-LightIt;
  src: url("./fonts/Neo/NeogroteskEss-LightIt.otf");
}
.neo-light-italic {
  font-family: NeogroteskEss-LightIt;
}