#amac-about {
    background-color: #F9FBFD;
    padding: 5em 0;
}

.about-desc {
    width: 85%;
}

.about-img {
    border-radius: 50px 0 50px 0;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.4);
}

.about-img img {
    border-radius: 50px 0 50px 0;
}

.about-numbers {
    margin-top: 8em;
}