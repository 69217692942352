/* Tickets */
.form-check-input:checked {
    background-color: #c83e38 !important;
    border-color: #c83e38 !important;
}

.ticket-cancel-btn {
    border: 1px solid #888888;
}

.ticket-cancel-btn:hover,
.ticket-cancel-btn:focus {
    border-color: #c83e38;
    color: #c83e38;
}

/* AMAC Video */
.past-amac-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-close-btn {
    position: absolute;
    top: -50px;
    right: 0px;
    background-color: #ff7158;
    border-radius: 50%;
    font-weight: bold !important;
}

.video-close-btn:hover,
.video-close-btn:focus {
    background-color: #ff6047;
}

@media (max-width: 768px) {
    /* .past-amac-thumbnail, */
    .past-amac-video {
        height: 300px;
    }
}

@media (max-width: 480px) {
    /* .past-amac-thumbnail, */
    .past-amac-video {
        height: 250px;
    }
}

@media (max-width: 400px) {
    /* .past-amac-thumbnail, */
    .past-amac-video {
        height: 200px;
    }
}

/* Speakers */
.no-rounded {
    border-radius: 0.3rem 0.3rem 0 0 !important;
}

.speakers-det-thumbnail {
    width: 200px;
    height: 200px;
    overflow: hidden;
}

.speakers-det-thumbnail img {
    width:100%;
    height:100%;
    object-fit: cover;
}

.speakers-det-thumbnail img {
    transition: transform 0.4s;
}
  
.speakers-det-thumbnail:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}